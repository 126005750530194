import React, { Component } from "react"

import Layout from "../../../components/layout"
import ProductPage from "../../../components/productpage"

import image_product from '../../../images/product/k402.jpg'
import image_product_mobile from '../../../images/product/k402_mobile.jpg'

class K402Page extends Component {

	render() {
		return (
		  <Layout mobileTitle={true} 
		  		customClass='product-title'
		  		activePath='product/flooring'
		  		pageTitle="Konstrukt&trade; Trafficshield&trade; K-402 Concrete Leveling Bond Coat">
		  	<ProductPage 
		  		pageTitle="Konstrukt&trade; Trafficshield&trade; K-402 Concrete Leveling Bond Coat"
		  		productID={"103"}
		  		image_product={image_product}
		  		image_product_mobile={image_product_mobile}
		  		nocalc={true}
		  		surfacelist={
		  			[
		  				{
		  					"code":0,
		  					"key":"",
		  					"name":""
		  				}
		  			]
		  		}
		  		/>
		  </Layout>
		);
	}
}
export default K402Page
